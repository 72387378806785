<template>
    <div class="orderEvaluation common-page">
      <my-nav title="工单流转" :self-back="true" @back="goBack"></my-nav>
      <div class="group-title color-blue">
        <span>{{ buttonInfo.nodeName }}</span>
        <span class="icon-arrow-blue" v-if="buttonInfo.disposeNodeName"></span>
        <span>{{ buttonInfo.disposeNodeName }}</span>
      </div>
      <van-cell-group>
        <van-cell>
          <template #title>
            <span class="cell-title-required"
              >整体评价</span
            >
          </template>
          <template #right-icon>
            <!-- <van-rate
              v-model="form.evaluateLevel"
              :size="25"
              color="#ffd21e"
              void-color="#eee"
            /> -->
            <van-rate
              v-model="form.evaluateLevel"
              :size="25"
              color="#F44A45"
              void-color="#eee"
            />
          </template>
        </van-cell>
      </van-cell-group>
      <van-field
        name="remark"
        v-model="form.evaluateDesc"
        label="评价描述"
        rows="3"
        autosize
        class="form-textarea"
        type="textarea"
        required
        placeholder="请输入评价描述"
      />
      <!-- <div class="remark mt-8">
        <div class="cell-title-required">评价描述</div>
        <div>
          <MyTextarea :value.sync="form.evaluateDesc" placeholder="请输入评价描述" />
        </div>
      </div> -->
      <div class="bottom-area">
        <template>
          <div class="button blue-reverse" @click="cuibanCancel">取消</div>
          <div class="button blue" @click="cuibanSubmit">确定</div>
        </template>
      </div>
      <div class="mat"></div>
    </div>
</template>

<script>
import api from '@/api/index'
import moment from 'moment'
export default {
  name: 'Evaluation',
  data () {
    return {
      show: false,
      form: {
        handleEnpoint: 'APP',
        evaluateDesc: '',
        evaluateLevel: 0
      },
      /* 催办类型显示逻辑和必填逻辑 */
      cuibanType: {
        required: true
      },
      buttonInfo: {},
      orderDetail: {},
      submitFlag: true // 最终能否允许用户提交表单的判断标识
    }
  },
  mounted () {
    this.orderDetail = this.$storage.get('orderDetail')
    this.form.orderNo = this.orderDetail.order_no
    this.buttonInfo = this.$storage.get('buttonInfo')
    this.form.buttonName = this.buttonInfo.buttonName
    this.form.curNodeId = this.buttonInfo.nodeId
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    /* 用户表单数据提交 */
    cuibanSubmit () {
      this.beforeSubmit()
      if (this.submitFlag) {
        let loadToast = this.$toast.loading({
          message: '请求中',
          forbidClick: true,
          duration: 0
        })
        this.form.commitTime = moment().format('YYYY-MM-DD HH:mm:ss')
        this.$http
          .post(api.OrderFlow, {
            ...this.form
          })
          .then((res) => {
            loadToast.clear()
            this.$toast.success('工单评价成功')
            setTimeout(() => {
              this.goBack()
            }, 600)
          })
      }
    },
    /* 提交前预处理 */
    beforeSubmit () {
      if (!this.form.evaluateLevel) {
        this.$toast('请选择评分')
        this.submitFlag = false
      } else {
        this.submitFlag = true
      }
      if (!this.form.evaluateDesc) {
        this.$toast('请输入评价描述')
        this.submitFlag = false
      } else {
        this.submitFlag = true
      }
    },
    /* 评价取消 */
    cuibanCancel () {
      this.goBack()
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>
